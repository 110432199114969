.el-dialog__body {
  padding: 10px;
}
.el-dialog__header {
  padding: 0px;
}
.el-dialog__footer {
  padding: 5px;
  border-top: 1px solid #ccc;
}
.el-dialog__headerbtn {
  font-size: 20px;
  background: white;
  top: 5px !important;
}
.md-menu {
  margin-right: 15px;
  cursor: pointer;
}
.md-menu:hover {
  background-color: #dae9f7;
}
.item-three {
  width: 32%;
  color: #409EFF;
}
.row-width {
  width: 98.5%;
}
.el-card__header {
  vertical-align: middle;
}
.el-table th {
  padding: 0 ;
}
.el-table thead {
  height: 15px;
  color: #0b0b3b;
  font-size: 12px;
  background: rebeccapurple;
}
.el-table td {
  padding: 5px ;
  font-size: 11px;
}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #3d77eb;
}
.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #3d77eb;
}
.atooltip {
  border: #1480ce 1px solid !important;
}
.el-tooltip__popper[x-placement^=right] .popper__arrow::after {
  border-right-color: #1480ce !important;
}
.el-tooltip__popper[x-placement^=right] .popper__arrow {
  border-right-color: #1480ce !important;
}
.wraper {
  width: 100%;
  margin: 0px auto;
}
.el-table .success-row {
  color: #959997;
}
.el-table .cancel-row {
  color: #e00e66;
}
.topback {
  border: 1px solid #E4E7ED;
  width: 60px;
  height: 60px;
  font-size: 12px;
  color: #409EFF;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0px;
  bottom: 275px;
  background-color: #fffffd;
  cursor: pointer;
  z-index: 999999;
}
.topfont {
  color: #409EFF;
  font-size: 16px;
}
.topback:hover {
  background-color: #2b85e4;
  color: white;
}
.messageBox .el-message-box__content {
  border-top: 1px solid #EBEEF5;
  height: 50px;
}
.el-message-box__message p {
  line-height: 25px;
}
.messageBox .el-message-box__btns {
  padding: 20px 15px 0;
}
.messageBox .el-message-box__header {
  background: #409eff;
}
.messageBox .el-message-box__title {
  color: white ;
  font-size: 14px;
}
