















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.ms-title {
    width: 100%;   
    background-color:#d7d7da;
    height:38px;
    line-height: 35px;
    margin-top:-1px;
    font-size: 10px;
    color: #2b85e4;
    margin-bottom:2px;
}
.active { 
    color:#D200D2; 
    text-decoration:none; 
} 
.oneMenu{
    line-height:140%;
    text-align:left;
    padding-left:5px;
    font-size:11px;
    color:white;
    background-color: #67c23a;
}
.twoMenu{
    display:flex;
    flex-direction:column;
    line-height:25px;
    background:white;
    padding-left:8px;

}
.twoMenu:hover{
    cursor: pointer;
    color:#409EFF;
}
.oneMenu:hover{
    cursor: pointer;
    color:#f4f6f8;
}
.el-cascader-menu{
    height:400px;
    width:100%;
}
.item-width  {
    width: 48%;
    height:25px;
}
.item-one  {
    width: 48%;
    height:100px;
}
.video-js .vjs-icon-placeholder {
    width: 80%;
    height: 80%;
    display: block;
}
.pub_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}
